<template>
    <h2>Users</h2>
    <div v-if="users.length ===0">
        <h3> error </h3>
    </div>
    <table v-else class="table__list">
        <thead>
            <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>info</th>
                <th><span class="btn btn__create">+</span></th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="user in users" :key="user.id">
                <td >{{ user.first_name}}</td>
                <td >{{ user.last_name}}</td>
                <td >{{ user.email}}</td>
                <td >{{ user.info}}</td>
                <td>
<!--                    <router-link :to="{name: 'UserEdit', params: {id: user.id}}" class="btn btn__edit" >Edit</router-link>-->
                    <span class="btn btn__edit" @click="editUser(user.id)">Edit</span>
                    <span class="btn btn__delete">DELETE</span></td>
            </tr>

        </tbody>
    </table>

</template>

<script>
    export default {
        name: 'UsersList',
        // props: {
        //     users: {
        //         type: Object,
        //     },
        //     error: String
        // },
        data() {
            return {
                // users: {}
            }
        },
        created() {
            this.$store.dispatch('fetchUsers');

        },
        computed: {
            users() {
                console.log('users list');
                console.log(this.$store.state.users);
                return this.$store.state.users;
            }
        },
        methods: {
            editUser(id) {
                console.log(id);
                this.$store.commit('setUserId', id);
                console.log(this.$store.state.userId);
                this.$router.push({name:'UserEdit', params: {id: id}})
            }
        }
    }
</script>
